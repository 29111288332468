<template>
    <div class="custom-form custom-form--date">
        <div :class="[error ? 'error' : '', 'form-group']">
            <label>{{ label }}</label>
            <date-picker
                format="YYYY-DD-MM"
                input-class="form-control"
                :defaultValue="val"
                :value="val"
                @input="(element) => $emit('input', element)"
                value-type="date"
                :range="range"
            >
                <!-- <template slot="input">
                    <input
                        type="text"
                        class="form-control"
                        v-model="val"
                        autocomplete="off"
                        :placeholder="placeholder"
                    />
                </template> -->
            </date-picker>
            <small v-if="error">{{ error }}</small>
        </div>
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

export default {
    name: "FormDatepicker",
    components: { DatePicker },
    props: {
        value: {
            type: Number | String,
            default: null,
        },
        range: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            val: null,
        };
    },
    mounted() {
        this.val = new Date(this.value);
    },
    watch: {
        value(value) {
            this.val = new Date(value);
        },
    },
};
</script>
