<template>
    <div class="row home-solutions">
        <template v-for="solutionsPart in solutions">
            <template @click.prevent v-for="s in solutionsPart">
                <div
                    class="card home-solutions-card col-sm-12 col-md-4"
                    :class="active === s.id ? 'bg-blue text-white' : 'bg-transparent'"
                    @click="toggle(s.id)"
                    :key="`title-${s.id}`"
                >
                    <div class="card-body px-0 px-md-3 p-5">
                        <div class="d-flex flex-column flex-md-row align-items-center">
                            <img
                                :src="`/images/frontpage/solution-0${s.id}${active === s.id ? '-active' : ''}.svg`"
                                class="home-solutions-card-img"
                                alt=""
                            />
                            <h3 class="font-base font-md-xl mb-0 ml-md-3 mt-2 mt-md-0 text-center text-md-left">
                                {{ s.info }}
                            </h3>
                        </div>
                    </div>
                </div>
            </template>
            <div class="col-12 bg-blue-light" v-for="s in solutionsPart" :key="`text-${s.id}`">
                <p class="explain" v-if="active === s.id">
                    {{ s.text }}
                    <a class="text-blue" href="/onboarding">Want to know more about it </a>
                </p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: "FrontpageSolutions",
    data() {
        return {
            solutions: [
                [
                    {
                        info: "Promote a corporate culture and sense of community",
                        id: 1,
                        text: "WorkAdventure is the perfect place to spread your corporate culture!  Whether it is on-site or off-site, your employees need to feel the heart of your values! Create a virtual environment that will transmit your sense of community and will help everybody to feel at home.\r\n \r\n",
                    },
                    {
                        info: "Improve remote team communication",
                        id: 2,
                        text: "WorkAdventure is your virtual place for business collaboration. Whether it is on-site or off-site, your employees need to properly know their work environment in order to fully enjoy and get the best of their physical and virtual office. To do so, we recommend defining a dedicated hybrid work ambassador.\r\n \r\n",
                    },
                    {
                        info: "Lead your change management process",
                        id: 3,
                        text: "WorkAdventure is your virtual place for business collaboration. Whether it is on-site or off-site, your managers and team leaders need to be ready to use WorkAdventure to smoothen operational processes & become more efficient in communication. \r\n \r\n",
                    },
                ],
                [
                    {
                        info: "Explore new ways of onboarding teammates",
                        id: 4,
                        text: "WorkAdventure is your virtual place for business collaboration. Whether it is on-site or off-site, your employees need to be onboarded to WorkAdventure. Preparing & taking the time to properly onboard your employees is a key factor of success. \r\n \r\n",
                    },
                    {
                        info: "Make your virtual interactions in the Metaverse more human",
                        id: 5,
                        text: "WorkAdventure is your virtual place for business collaboration that brings your employees together. Whether it is on-site or off-site, you need to create a safe and joyful environment for your employees to be completely a part of your team. \r\n \r\n",
                    },
                    {
                        info: "Promote remote and hybrid team building activities",
                        id: 6,
                        text: "WorkAdventure is your virtual place for business collaboration that brings your employees together. Whether it is on-site or off-site, it’s a professional yet fun place where team building sessions take another turn! Imagine a world where your employees, regardless of where they are, can meet up for social activities and get to know each other. \r\n \r\n",
                    },
                ],
            ],
            active: null,
        };
    },
    methods: {
        toggle(i) {
            this.active = this.active === i ? null : i;
        },
    },
};
</script>
