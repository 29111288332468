<template>
    <div class="form-toggle">
        <label class="toggle-label" :for="id">
            <span class="toggle-val font-s">{{ defaultValue }}</span>
            <div class="toggle-wrap">
                <input type="checkbox" class="toggle-input" :id="id" :value="toggleValue" v-model="val" />
                <span class="toggle-indicator"></span>
            </div>
            <!-- toggle -->
            <span class="toggle-val font-s">{{ toggleLabel }}</span>
        </label>
    </div>
</template>
<script>
export default {
    name: "FormToggle",
    props: {
        defaultValue: {
            type: [Number, String],
            required: true,
        },
        toggleLabel: {
            type: [Number, String],
            default: null,
        },
        toggleValue: {
            type: [Number, String, Boolean],
            default: false,
        },
        value: {
            type: [Number, String, Boolean],
            default: false,
        },
    },
    data() {
        return {
            val: this.value,
            id: null,
        };
    },
    watch: {
        val(v) {
            this.$emit("input", v);
        },
        value(v) {
            this.val = v;
        },
    },
    mounted() {
        this.id = Math.random().toString(36).substring(7);
    },
};
</script>
