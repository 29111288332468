<template>
    <div class="custom-form custom-form--select">
        <div :class="[error ? 'error' : '', 'form-group']">
            <label :for="givenId">{{ label }}</label>
            <select
                :name="name"
                v-model="val"
                :id="givenId"
                class="form-control"
                :required="required"
                :aria-describedby="`help-${givenId}`"
            >
                <option v-if="placeholder !== null" :value="null">{{ placeholder }}</option>
                <slot />
            </select>
            <small :id="`help-${givenId}`" class="text-muted"></small>
            <small v-if="error">{{ error }}</small>
        </div>
    </div>
</template>
<script>
export default {
    name: "FormSelect",
    props: {
        label: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        success: {
            type: String,
            default: null,
        },
        value: {
            type: String | Number | Object,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            val: this.value,
        };
    },
    computed: {
        givenId() {
            if (this.id !== null) {
                return this.id;
            } else {
                var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
                    arr = [];
                for (let index = 0; index <= 10; index++) {
                    arr.push(chars[Math.floor(Math.random() * chars.length)]);
                }
                return arr.join("");
            }
        },
    },
    watch: {
        val(v) {
            this.$emit("input", v);
        },
        value(v) {
            this.val = v;
        },
    },
};
</script>
